<template>
    <div style="height:100%;">
        <a-layout style="height:100%;">
            <!-- <a-breadcrumb style="margin: 6px 10px 0 ;">
                <a-button style="float:right" href="#" onclick="window.history.go(-1); return false;">返回</a-button>
            </a-breadcrumb> -->
            <a-layout-content style="height:100%;">
                <div class="yj-conten" style="min-height: calc(100% - 48px); background-color:#fff;width:calc(100% - 32px);min-width:max-content">
                    <a-card :bordered="false"
                            :tab-list="tabListNoTitle"
                            :active-tab-key="noTitleKey"
                            @tabChange="key => onTabChange(key, 'noTitleKey')">
                        <p v-if="noTitleKey === 'copywriting'">
                           <Moment-detail :id="id"></Moment-detail>
                        </p>
                        <p v-else-if="noTitleKey === 'nativecontent'">
                             <Extension-detail :id="id"></Extension-detail>
                        </p>

                    </a-card>

                </div>
            </a-layout-content>
        </a-layout>
    </div>
</template>
<script type="text/javascript" charset="utf-8" src="~/Scripts/ueditor.config.js"></script>
<script type="text/javascript" charset="utf-8" src="~/Scripts/ueditor.all.js"></script>
<script type="text/javascript">
import http from "@/Plugin/Http.js";
//import imagedata from "@/Plugin/UploadDataHelper.js";
import MomentDetail from "./MomentDetail";
import ExtensionDetail from "./ExtensionDetail";
import Rich  from "@/components/RichEditor.vue";
    export default {
        name: "MaterialDetail",
        data() {
            return {
                tabListNoTitle: [
                    {
                        key: 'copywriting',
                        tab: '朋友圈',
                    },

                    {
                        key: 'nativecontent',
                        tab: '推广文章',
                    },

                ],
                noTitleKey: 'copywriting',
                headers: {
                    authorization: 'authorization-text',
                },
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 3 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 20 },
                    },
                },
                config: {
                    rules: [{ type: 'object', required: true, message: 'Please select time!' }],
                },
                rangeConfig: {
                    rules: [{ type: 'array', required: true, message: 'Please select time!' }],
                }
            };

        },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: 'time_related_controls' });
        },
        components: {
            Rich,
            MomentDetail,
            ExtensionDetail
        },
        methods: {
            onTabChange(key, type) {
                console.log(key, type);
                this[type] = key;
            },
            async getParams() {
                this.id = this.$route.query.id
            }
        },
        created: function () {
            this.getParams();
        }
    }
</script>